import React from "react";
// import PropTypes from "prop-types";

// Material UI imports.
import {
	Button,
	Box,
	TextField,
	Tooltip, Typography, IconButton
} from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

// Used for storing relevant strategy data across components.
import {
	StrategyContext, addNewStrategy, addNewGroup, REDIRECT_TIMEOUT_MS
} from "../../context/StrategyContext.js";
import CenterModal from "../CenterModal.js";
import { getGroupStrategies } from "../../lib/strategy-ops.js";

class StrategyList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"show_all" : {},
			"visible1" : false,
			"new_group_title" : "",
			"current_strategy_page" : 1
		};

		this.renderSimulationTree = this.renderSimulationTree.bind(this);
		this.renderStrategyTree = this.renderStrategyTree.bind(this);
		this.renderGroupTree = this.renderGroupTree.bind(this);
		this.renderContinuousSubmissionTree = this.renderContinuousSubmissionTree.bind(this);
		this.updateNewGroupTitle = this.updateNewGroupTitle.bind(this);
		this.handlePagination = this.handlePagination.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {};

	componentDidUpdate(prevProps) {
		const {
			clicked_add_strategy, clicked_add_group, selected_leaf, setContext
		} = this.context;

		// Checks if DashboardStart component clicked "Create New Group" or "Create New Strategy"

		if (clicked_add_group === true) {
			setContext({"clicked_add_group" : false}, () => {
				this.setState({"visible1" : true});
			});
		}
		if (clicked_add_strategy === true) {
			setContext({"clicked_add_strategy" : false}, () => {
				addNewStrategy(selected_leaf.id, this.context);
			});
		}
	}

	render() {
		let {
			groups, selected_node_id,
			expanded_node_ids, membership_tier,
			collapse_list
		} = this.context;
		let { visible1, new_group_title } = this.state;

		return (
			<Box
				className="strategy-list"
				sx={{
					"backgroundColor" : "background.paper",
					"display" : collapse_list ? "none" : undefined
				}}
			>
				<TreeView
					expanded={expanded_node_ids}
					selected={selected_node_id}
					multiSelect={false} // Only one leaf may be selected at a time.
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					onNodeToggle={(a, b) => {
						this.setState({"show_all" : {}});
					}}
					sx={{"width" : "100%"}}
				>
					{ this.renderContinuousSubmissionTree() }
					{ groups && this.renderGroupTree(groups) }

					<TreeItem
						nodeId={"add_group"}
						label="Add Group"
						sx={{"color" : "font.main"}}
						onClick={() => {
							this.setState({"visible1" : true});
						}} />

				</TreeView>

				<CenterModal
					is_open={visible1}
					title="Create New Group"
					onClose={() => {
						this.setState({
							"visible1" : false,
							"new_group_title" : ""
						});
					}}
				>
						<TextField
							type="text"
							name="new_group_title"
							label="New Group Title"
							value={new_group_title}
							onChange={this.updateNewGroupTitle}
							size="small"
							InputProps={{"sx" : {"typography" : "body2", "color" : "font.main"}}}
							InputLabelProps={{"sx" : {"typography" : "body2"}}}
						/>

						<Box
							className="flex-row-space-between"
							sx={{
								"margin": "40px 0px 0px 0px",
								"width" : "100%"
							}}
						>
							<Tooltip title="Create strategies by hand.">
								<span>
								<Button
									variant="contained"
									onClick={() => {
										addNewGroup(new_group_title, "custom", this.context, err=>{
											if (err) return console.error(err);
											this.setState({
												"visible1" : false,
												"new_group_title" : ""
											});
										});
									}}
									sx={{"color":"font.button","marginRight":"10px"}}
								>
									Custom Group
								</Button>
								</span>
							</Tooltip>

							<Tooltip title="Generate strategies automatically.">
								<span>
								<Button
									variant="contained"
									disabled={membership_tier === "demo"}
									onClick={() => {
										addNewGroup(new_group_title, "template", this.context, err => {
											if (err) return console.error(err);
											this.setState({
												"visible1" : false,
												"new_group_title" : ""
											});
										})
									}}
									sx={{"color":"font.button","marginLeft":"10px"}}
								>
									Template Group
								</Button>
								</span>
							</Tooltip>
						</Box>
				</CenterModal>
			</Box>
		);
	}

	updateNewGroupTitle(evt) {
		let s = evt.target.value;
		this.setState({ "new_group_title" : s });
	}

	renderGroupTree(groups) {
		const {
			selected_leaf, selected_branch, strategies, setPage, setContext,
			current_strategy_max_pages, 
		} = this.context;
		const { current_strategy_page } = this.state;

		return groups.map(g => {
			const gk = `group-${g.id}`;

			const pagination_sx = {
				"fontSize" : "2.5em",
				"color" : "secondary.main"
			};
			const onPagination = (new_page) => {
				this.handlePagination(g.id, new_page);
			};

			return (
				<TreeItem
					key={gk}
					nodeId={gk}
					label={g.title}
					sx={{"color" : "font.main"}}
					onClick={(e) => {
						e.preventDefault();
						if (selected_leaf?.id === g.id && selected_branch === "group") {
							setContext({
								"selected_branch" : null,
								"selected_leaf" : null,
								"selected_node_id" : "",
								"expanded_node_ids" : []
							}, () => {
								setPage(`/dashboard`);
							});
							return;
						}

						setContext({
							"is_loading" : true,
							"loading_msg" : `Loading ${gk}...`
						}, () => {
							/// ooooooooff anything more elegant than this?
							// this.setState({ "redirect" : `/dashboard/group-${s.group_id}/strategy-${s.id}` });
							setTimeout(() => {
								setPage(`/dashboard/${gk}`);
							}, REDIRECT_TIMEOUT_MS);
						});
					}}
				>
					{
						strategies && strategies[gk] &&
						this.renderStrategyTree(strategies[gk])
					}

					<Box
						className="flex-row-center"
						sx={{"alignItems" : "center"}}
					>
						<IconButton
							component={SkipPreviousIcon}
							sx={pagination_sx}
							disabled={current_strategy_page === 1}
							onClick={() => {
								onPagination(1);
							}}
						/>
						<IconButton
							component={ChevronLeftIcon}
							sx={pagination_sx}
							disabled={current_strategy_page === 1}
							onClick={() => {
								onPagination(current_strategy_page - 1);
							}}
						/>

						<Box>
							<Typography
								color="font.main"
								sx={{"fontSize":"1.2em"}}
							>
								{current_strategy_page}
							</Typography>
						</Box>

						{
							g.type !== "template" && 
							<Tooltip title="Create New Strategy">
								<IconButton
									component={AddCircleOutlinedIcon}
									sx={{"fontSize":"2.3em", "color":"success.main"}}
									onClick={() => {
										addNewStrategy(g.id, this.context)
									}}
								/>
							</Tooltip>
						}

						<IconButton
							component={ChevronRightIcon}
							sx={pagination_sx}
							disabled={current_strategy_page === current_strategy_max_pages}
							onClick={() => {
								onPagination(current_strategy_page + 1);
							}}
						/>
						<IconButton
							component={SkipNextIcon}
							sx={pagination_sx}
							disabled={current_strategy_page === current_strategy_max_pages}
							onClick={() => {
								onPagination(current_strategy_max_pages);
							}}
						/>
					</Box>

				</TreeItem>
			);
		});
	}

	renderStrategyTree(strats) {
		const { show_all } = this.state;
		const {
			selected_leaf, simulations, setPage, setContext
		} = this.context;

		return strats.map(s => {
			// const is_selected = selected_strategy && selected_strategy.id === s.id;
			let current_simulations = simulations[`strategy-${s.id}`];
			if (current_simulations && !show_all[`strategy-${s.id}`]) {
				current_simulations = current_simulations.slice(0, 10);
			}

			/*const label_text = (current_simulations && !show_all[`strategy-${s.id}`]) ?
						`Show All (${simulations[`strategy-${s.id}`].length})`
						:
						"Hide Extra";*/

			return (
				<TreeItem
					key={`strategy-${s.id}`}
					nodeId={`strategy-${s.id}`}
					label={s.title }
					sx={{"color" : "font.main"}}
					onClick={(e) => {
						e.preventDefault();
						if (selected_leaf?.id === s.id && selected_leaf?.group_id === s.group_id) {
							setContext({
								"is_loading" : true,
								"loading_msg" : `Loading group-${s.group_id}...`
							}, () => {
								/// ooooooooff anything more elegant than this?
								// this.setState({ "redirect" : `/dashboard/group-${s.group_id}/strategy-${s.id}` });
								setTimeout(() => {
									setPage(`/dashboard/group-${s.group_id}`);
								}, REDIRECT_TIMEOUT_MS);
							});
							return;
						}

						setContext({
							"is_loading" : true,
							"loading_msg" : `Loading strategy-${s.id}...`
						}, () => {
							/// ooooooooff anything more elegant than this?
							// this.setState({ "redirect" : `/dashboard/group-${s.group_id}/strategy-${s.id}` });

							setTimeout(() => {
								setPage(`/dashboard/group-${s.group_id}/strategy-${s.id}`);
							}, REDIRECT_TIMEOUT_MS);
						});
					}}
				>
					{this.renderSimulationTree(current_simulations, s.group_id)}
				</TreeItem>
			);
		});
	}

	renderSimulationTree(sims, group_id) {
		const { setPage, setContext } = this.context;

		if (!sims) return <div />

		return sims.map((cs, idx) => {
			const label_node = (<div>simulation-{cs.id}</div>);

			return (
				<TreeItem
					key={`simulation-${cs.id}`}
					nodeId={`simulation-${cs.id}`}
					sx={{"color" : "font.main"}}
					label={label_node}
					onClick={(e) => {
						e.preventDefault();
						setContext({
							"is_loading" : true,
							"loading_msg" : `Loading simulation-${cs.id}...`
						}, () => {
							setTimeout(() => {
								setPage(`/dashboard/group-${group_id}/strategy-${cs.strategy_id}/simulation-${cs.id}`);
							}, REDIRECT_TIMEOUT_MS);
						});
					}}
				/>
			);
		});
	}

	renderContinuousSubmissionTree() {
		const {
			strategies, selected_node_id, setContext, setPage, selected_leaf
		} = this.context;
		const continuous_group_node_id = "continuous_group";

		let arr = strategies["continuous"] || [];

		return (
			<TreeItem
				nodeId={continuous_group_node_id}
				label="All Continuous"
				sx={{"color" : "font.main"}}
				onClick={() => {
					if (selected_node_id === continuous_group_node_id) {
						setContext({
							"selected_branch" : null,
							"selected_leaf" : null,
							"selected_node_id" : "",
							"expanded_node_ids" : []
						}, () => {
							setPage(`/dashboard`);
						});
					} else {
						setContext({
							"selected_branch" : null,
							"selected_leaf" : null,
							"selected_node_id" : continuous_group_node_id,
							"expanded_node_ids" : [continuous_group_node_id]
						}, () => {
							setPage(`/dashboard`);
						});
					}
				}}
			>
				{
					arr.map(s => {
						const sk = `continuous-strategy-${s.id}`;
						return (

							<TreeItem
								key={sk}
								nodeId={sk}
								label={s.title}
								sx={{"color" : "font.main"}}
								onClick={(e) => {
									e.preventDefault();
	
									if (selected_leaf?.id === s.id && selected_leaf?.group_id === s.group_id) return;
									setTimeout(() => {
										setPage(`/dashboard/group-${s.group_id}/strategy-${s.id}`);
									}, REDIRECT_TIMEOUT_MS);
								}}
							/>
						);
					})
				}
			</TreeItem>
		);
	}

	/* TODO: This is another good candidate of something that should be pulled into its own context operation, copied from loadGroup in GroupView.js */
	handlePagination(group_id, new_page) {
		const { strategies, strat_page_size, setContext } = this.context;
		const gk = `group-${group_id}`;

		setContext({
			"is_loading" : true,
			"loading_msg" : "Loading strategies..."
		});

		getGroupStrategies(group_id, new_page, strat_page_size, (err1, res1) => {
			if (err1) {
				console.error(err1);
				return;
			}

			strategies[gk] = res1.strategies;
			this.setState({"current_strategy_page" : new_page}, () => {
				setContext({
					strategies,
					"current_strategy_max_pages" : res1["pages"],
					"is_loading" : false,
					"loading_msg" : null
				});
			});
		});
	}
}

export default StrategyList;
