import React from "react";
import PropTypes from "prop-types";

import {
	TextField, Box, Typography, Select, MenuItem, Tooltip, IconButton
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
	StrategyContext, NUMBER_RGX
} from "../../../context/StrategyContext.js";
import DurationInput from "../DurationInput.js";
import { MIN_GRANULARITY_MS } from "../../../lib/duration-ops.js";

import "../../../css/template.css";
const NON_NUMERAL_EXCEPTIONS = [ "", ".", "-"];

class TemplateInputField extends React.Component{
	constructor(props) {
		super(props);

		this.buildStateFromProps = this.buildStateFromProps.bind(this);
		this.addOption = this.addOption.bind(this);
		this.removeOption = this.removeOption.bind(this);
		this.updateOption = this.updateOption.bind(this);
		this.makeRange = this.makeRange.bind(this);
		this.updateRange = this.updateRange.bind(this);
		this.updateBool = this.updateBool.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.clampRange = this.clampRange.bind(this);

		this.state = this.buildStateFromProps(props);
	}

	static contextType = StrategyContext;

	static propTypes = {
		"label" : PropTypes.string.isRequired,
		"more_info" : PropTypes.string.isRequired,
		"field_name" : PropTypes.string.isRequired,
		"value" : PropTypes.object,
		"datatype" : PropTypes.oneOf([
			"float", "integer", "duration", "boolean"
		]).isRequired,
		"boundaries" : PropTypes.shape({
			"abs_min" : PropTypes.number,
			"abs_max" : PropTypes.number,
			"min_step" : PropTypes.number
		}),
		"onChange" : PropTypes.func.isRequired,
		"is_disabled" : PropTypes.bool
	}

	componentDidUpdate(prevProps) {
		const a = JSON.stringify(prevProps);
		const b = JSON.stringify(this.props);

		if (a !== b) {
			this.setState(this.buildStateFromProps(this.props));
		}
	}

	render() {
		const { setContext } = this.context;
		const { label, more_info, datatype, is_disabled } = this.props;
		const {
			is_value, is_options, is_range,
			value, options, range
		} = this.state;

		const is_boolean = datatype === "boolean";
		let boolean_value = undefined;
		if (is_boolean && is_value) boolean_value = (value === true) ? "T" : "F";
		else if (is_boolean && is_options) boolean_value = "E";

		const text_field_input_props = {
			"sx" : {
				"typography":"body2",
				"color" : "font.main"
			}
		};

		return (
			<Box className="template-input-field">
				<Box
					className="flex-row-space-between"
					sx={{
						"width" : "100%",
						"margin" : "0px 0px 15px 0px"
					}}
				>
					<Box className="flex-row-center">
						<Typography
							color="font.main"
							variant="body2"
							sx={{"marginRight" : "5px"}}
						>{label}</Typography>

						<Tooltip title={more_info}>
							<InfoOutlinedIcon sx={{"color" : "font.main"}} fontSize="xs" />
						</Tooltip>
					</Box>

					<Box className="flex-row-start">
						<Tooltip
							arrow
							title={is_disabled || is_boolean ? "" : "Specific values that the Engine will choose from."}
							enterDelay={500}
						>
							<span>
								<IconButton
									disabled={is_disabled}
									onClick={this.addOption}
								>
									<AddCircleOutlineIcon
										color="primary"
										sx={{"visibility" : is_disabled || is_boolean ? "hidden" : undefined}}
									/>
								</IconButton>
							</span>
						</Tooltip>

						<Tooltip
							arrow
							title={is_disabled || is_boolean ? "" : "Upper/lower bounds that the Engine will select between."}
							enterDelay={500}
						>
							<span>
								<IconButton
									disabled={is_disabled}
									onClick={this.makeRange}
								>
									<LinearScaleIcon
										color="secondary"
										sx={{"visibility" : is_disabled || is_boolean ? "hidden" : undefined}}
									/>
								</IconButton>
							</span>
						</Tooltip>
					</Box>
				</Box>


				{
					is_boolean &&
					<Box
						className="flex-row-center"
						sx={{
							"height" : "100%",
							"marginBottom" : "50px"
						}}
					>
						<Select
							size="small"
							sx={{"color" : "font.main"}}
							onOpen={evt => {
								if (is_disabled) evt.preventDefault();
							}}
							value={boolean_value}
							onChange={this.updateBool}
						>
							<MenuItem sx={{"color" : "font.main"}} value={"T"}>True</MenuItem>
							<MenuItem sx={{"color" : "font.main"}} value={"F"}>False</MenuItem>
							<MenuItem sx={{"color" : "font.main"}} value={"E"}>Either</MenuItem>
						</Select>
					</Box>
				}

				{
					!is_boolean && is_value && (
						datatype === "duration" ? (
							<DurationInput
								className="template-input-value"
								is_disabled={is_disabled}
								onChange={(ms) => {
									const r = ms % MIN_GRANULARITY_MS;
									if (r !== 0) {
										ms -= r;
										setContext({
											"snackbar_sev" : "warning",
											"snackbar_msg" : "Please enter groups of 10 minutes."
										});
									}
									this.updateValue({"target" : {"value" : ms}});
								}}
								value={value} />
						)
						:
						(
							<TextField
								className="template-input-value"
								disabled={is_disabled}
								name="value"
								label="Value"
								size="small"
								InputProps={text_field_input_props}
								InputLabelProps={text_field_input_props}
								value={value}
								onChange={this.updateValue} />
						)
					)
				}

				{
					!is_boolean && is_options &&
					options.map(o => {
						return datatype === "duration" ? (
							<Box key={o.key}>
								<DurationInput
									className="template-input-option"
									onChange={(ms) => {
										const r = ms % MIN_GRANULARITY_MS;
										if (r !== 0) {
											ms -= r;
											setContext({
												"snackbar_sev" : "warning",
												"snackbar_msg" : "Please enter groups of 10 minutes."
											});
										}

										this.updateOption({
											"target" : {
												"name" : o.key,
												"value" : ms
											}
										});
									}}
									value={o.value}
									is_disabled={is_disabled}
								/>
								<Tooltip title="Remove Option">
									{/* Added <span /> for the Tooltip component. */}
									<span>
										<IconButton
											disabled={is_disabled || options.length === 1}
											onClick={() => {this.removeOption(o)}}
										>
											<RemoveCircleOutlineIcon
												color={
													(is_disabled || options.length === 1) ?
													"black" : "error"
												}
											/>
										</IconButton>
									</span>
								</Tooltip>
							</Box>
						) : (
							<Box
								key={o.key}
								className="flex-row-start"
							>
								<TextField
									className="template-input-option"
									name={o.key}
									id={o.key}
									key={o.key}
									disabled={is_disabled}
									size="small"
									InputProps={text_field_input_props}
									InputLabelProps={text_field_input_props}
									value={o.value}
									onChange={this.updateOption}
								/>
								<Tooltip title="Remove Option">
									{/* Added <span /> for the Tooltip component. */}
									<span>
										<IconButton
											disabled={is_disabled || options.length === 1}
											onClick={() => {this.removeOption(o)}}
										>
											<RemoveCircleOutlineIcon
												color={
													(is_disabled || options.length === 1) ?
													"black" : "error"
												}
											/>
										</IconButton>
									</span>
								</Tooltip>
							</Box>
						);
					})
				}

				{
					!is_boolean && is_range &&
					["Min", "Max", "Step"].map(s => {
						return datatype === "duration" ? (
							<DurationInput
								key={s}
								label={s}
								is_disabled={is_disabled}
								class_name="template-input-range"
								onChange={(ms) => {
									const r = ms % MIN_GRANULARITY_MS;
									if (r !== 0) {
										ms = 100;
										setContext({
											"snackbar_sev" : "warning",
											"snackbar_msg" : "Please enter groups of 10 minutes."
										});
									}
									this.updateRange({
										"target" : {
											"name" : s.toLowerCase(),
											"value" : ms
										}
									});
								}}
								value={range[s.toLowerCase()]} />
						) : (
							<TextField
								className="template-input-range"
								name={s.toLowerCase()}
								key={s}
								label={s}
								size="small"
								disabled={is_disabled}
								InputProps={text_field_input_props}
								InputLabelProps={text_field_input_props}
								value={range[s.toLowerCase()]}
								onChange={this.updateRange}/>
						);
					})
				}
				
			</Box>
		);
	}

	buildStateFromProps() {
		const { datatype, "value" : input, boundaries } = this.props;
		const is_boolean = datatype === "boolean";

		let new_state = {
			"is_value" : input == null || input.value != null,
			"is_options" : input != null && input.options != null,
			"is_range" : !is_boolean && input != null && input.min != null && input.max != null && input.step != null,
			"value" : 0,
			"options" : [],
			"range" : { "min" : 0, "max" : 1, "step": 0.1 }
		};

		// TODO: confirm boundaries has a valid structure.
		if (!is_boolean && (new_state.is_range || boundaries)) {
			new_state.range = {
				"min" : new_state.is_range ? input.min : boundaries.abs_min,
				"max" : new_state.is_range ? input.max : boundaries.abs_max,
				"step": new_state.is_range ? input.step : boundaries.min_step
			};
		}

		if (new_state.is_value) {
			if (is_boolean) new_state.value = input != null ? input.value : false;
			else if (input == null) new_state.value = boundaries.abs_min;
			else if (input.value != null) new_state.value = input.value;

			if (is_boolean) return new_state;

			// Perform mapping from single value to "option"
			new_state.is_value = false;
			new_state.is_options = true;
			new_state.options = [{"key" : "0", "value" : new_state.value}];

		} else if (new_state.is_options) {
			new_state.options = input.options.map((o, idx) => {
				return {"key" : `${idx}`, "value" : o};
			});
		} else if (new_state.is_range) {
// 			new_state.range = this.clampRange({ ...input }, boundaries);
		}

		return new_state;
	}

	/**
	 * is_true
	 * is_either represents the lack of a value for a boolean.
	 *
	 * The concept of a "range" doesn't make sense for a boolean,
	 * so the checks are only for a 'value' (true OR false) and
	 * an "option" (true AND false).
	 *
	 * This is reflected in the engine, if options is selected then there
	 * is a 50/50 chance of either true or false being selected for that
	 * particular simulation.
	 *
	 */
	updateBool(evt) {
		const { value } = evt.target;
		const { field_name, onChange } = this.props;

		const is_true = value === "T"; // User selected true
		const is_either = value === "E"; // User selected true and false.

		this.setState({
			"is_value" : !is_either,
			"value" : is_either ? undefined : is_true, // TODO: Confirm this doesn't overwrite a previously stored this.state.value. Do we want to save it?
			"is_options" : is_either,
			"options" : is_either ? [true, false] : undefined,
			"is_range" : false
		}, () => {
			const o1 = { "options" : [true, false] };
			const o2 = { "value" : is_true };
			onChange(field_name, is_either ? o1 : o2);
		});
	}

	addOption() {
		// TODO: Is this a bug, I'm take the variable options, which is a reference to state, pushing to it, and then setState with the same object
		const { is_options, value, options } = this.state;
		const { boundaries, datatype, field_name, onChange } = this.props;
		const is_boolean = datatype === "boolean";
		const new_uuid = `${options.length}`;

		const done = (new_options) =>  {
			this.setState({
				"is_value" : false,
				"is_options" : true,
				"is_range" : false,
				"options" : new_options
			}, () => {
				onChange(field_name, { "options" : new_options.map(no => no.value) });
			});
		}

		// Handles switching from range / value to options.
		if (!is_options) {
			if (!options || options.length === 0) return done([{ "key" : "0", value}]); // Empty options means first time clicking add option.
			else return done(options.slice(0, 1));
		}
		
		if (is_boolean) {
			if (options.length === 2) return;
			
			options.push({ "key" : new_uuid, "value" : !value});
		} else {
			let new_value = boundaries.abs_min;
			while (options.map(o => o.value).includes(new_value)) new_value += boundaries.min_step;
			options.push({ "key" : new_uuid, "value" : new_value});
		}

		return done(options);
	}

	removeOption(option_value) {
		const { field_name, onChange} = this.props;
		const { options } = this.state;

		if (options.length === 1) return;

		let new_options = options.filter(o => o.key !== option_value.key);
		onChange(field_name, { "options" : new_options.map(no => no.value) });
		this.setState({ "options" : new_options });
	}

	updateOption(evt) {
		let { name, value } = evt.target;
		const { field_name, boundaries, onChange } = this.props;
		const options = [ ...this.state.options ];
		const is_nne = NON_NUMERAL_EXCEPTIONS.includes(value);
		if (!NUMBER_RGX.test(value) && !is_nne) return;
		const is_zero = is_nne || Number(value) === 0.0;
		value = is_zero ? value : this.clampRange(value, boundaries);

		for (const i in options) {
			if (options[i].key === name) {
				options[i].value = value;
				this.setState({options});
				if (!is_zero) onChange(field_name, { "options" : options.map(o => o.value) });
				break;
			}
		}
	}

	makeRange() {
		const { field_name, onChange } = this.props;

		this.setState({
			"is_value" : false,
			"is_options" : false,
			"is_range" : true,
		});

		onChange(field_name, { ...this.state.range });
	}
	
	updateRange(evt) {
		// NOTE: Regardless of the value of "datatype", value is provided as a string.
		let { name, value } = evt.target;
		const { field_name, boundaries, onChange, is_disabled } = this.props;
		if (is_disabled) return;

		const is_nne = NON_NUMERAL_EXCEPTIONS.includes(value);
		if (!NUMBER_RGX.test(value) && !is_nne) return;
		const is_zero = is_nne || Number(value) === 0.0;

		let range = {
			...this.state.range,
			[name] : is_zero ? value : this.clampRange(value, boundaries)
		};
		this.setState({ range });

		if (!is_zero) onChange(field_name, range);
	}

	updateValue(evt) {
		let { value } = evt.target;
		const { field_name, boundaries, onChange, is_disabled } = this.props;
		if (is_disabled) return evt.preventDefault();

		const is_nne = NON_NUMERAL_EXCEPTIONS.includes(value);
		if (!NUMBER_RGX.test(value) && !is_nne) return;
		const is_zero = is_nne || Number(value) === 0.0;
		value = is_zero ? value : this.clampRange(value, boundaries);

		this.setState({ value });
		if (!is_zero) onChange(field_name, value);
	}

	clampRange(value, boundaries) {
		const { datatype, is_disabled } = this.props;
		const { abs_min, abs_max } = boundaries;
		let v = null;
		if (is_disabled) return;

		if (value === "" || isNaN(value)) return value;
		else if (datatype === "float") v = Number(value);
		else if (datatype === "integer") v = parseInt(value);
		else return value;

		if (v < abs_min) return abs_min;
		else if (v > abs_max) return abs_max;
		else return v;
	}
}

export default TemplateInputField;
