import React from "react";
import PropTypes from "prop-types";

import {
	Box, Container, Grid, Paper, Typography, IconButton, Tooltip
} from "@mui/material";

import TemplateInputField from "./TemplateInputField.js";
import TemplateInputSignal from "./TemplateInputSignal.js";
import { StrategyContext } from "../../../context/StrategyContext.js";

import { msToDuration } from "../../../lib/duration-ops.js";

import "../../../css/template.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const FLOAT_BOUNDARIES = {
	"abs_min" : 0.0001, "abs_max" : 10.0, "min_step" : 0.0001
};

// const int_boundaries = { "abs_min" : 1, "abs_max" : 10, "min_step" : 1}
const DEFAULT_SIGNAL = {
	"indicator_title" : null, "title" : null, "signal_type" : null,
	"parameters" : {},
	"granularity" : { "value" : 14400000 },
	"direction" : { "options" : ["L", "S"] },
	"optional" : false
};


class TemplateEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"template" : props.template,
			"default_signal" : DEFAULT_SIGNAL,
			"new_signal" : { ...DEFAULT_SIGNAL },
			"last_update" : new Date().getTime(),
			"editing" : { "N" : [], "X" : [] }
		};

		this.updateEntraceSignal = this.updateEntranceSignal.bind(this);
		this.updateExitSignal = this.updateExitSignal.bind(this);
		this.pushNewSignal = this.pushNewSignal.bind(this);
		this.startEditingSignal = this.startEditingSignal.bind(this);
		this.stopEditingSignal = this.stopEditingSignal.bind(this);
		this.templateUpdate = this.templateUpdate.bind(this);
		this.setEditingLength = this.setEditingLength.bind(this);
		this.deleteSignal = this.deleteSignal.bind(this);
		this.buildLineFromSignal = this.buildLineFromSignal.bind(this);
	}

	static contextType = StrategyContext;
	static propTypes = {
		"is_disabled" : PropTypes.bool,
		"template" : PropTypes.object.isRequired,
		"onChange" : PropTypes.func.isRequired,
	};

	componentDidUpdate() {
		const { last_update, template } = this.state;

		const curr = JSON.stringify(this.props.template);
		const prev = JSON.stringify(template);
		const d = new Date().getTime() - last_update;
		/**
		 * If the ID of the template has changed (ie, if a different template
		 * has been selected) then update template in widget.
		 *
		 * Additionally, only update once every 250ms. This ensures that many rapid updates don't cause this to crash.
		 */
		if (curr !== prev && d >= 250) {
			this.setState({
				"template" : this.props.template,
				"last_update" : new Date().getTime()
			}, this.setEditingLength);
		}
	}


	render() {
		const { indicator_pool, setContext } = this.context;
		const { is_disabled } = this.props;
		const { template, new_signal, editing } = this.state;
		const { entrance_signals, exit_signals } = template;

		const title = is_disabled ? "Viewer" : "Editor";

		return (
			<Box sx={{"width":"100%"}}>
				<Box sx={{"width" : "100%"}}>
					<Box className="flex-row-start">
						<Typography
							color="font.main"
							variant="h2"
						>Template {title}</Typography>
						<IconButton
							onClick={() => {
								const raw_temp = JSON.stringify(template, null, 4);
								navigator.clipboard.writeText(raw_temp);
								setContext({
									"snackbar_msg" : "Copied Template",
									"snackbar_sev" : "success"
								});
							}}
						>
							<Tooltip title="Copy Template JSON">
								<ContentCopyIcon color="primary" />
							</Tooltip>
						</IconButton>
					</Box>
					<Box className="template-enter-conditions">
						<Grid
							container
							spacing={0}
							sx={{"justifyContent" : "space-evenly"}}
						>
							<Grid
								item
								component={Paper}
								xs={3}
								className="template-grid-tile"
							>
								<TemplateInputField
									label="Spread Limit"
									is_disabled={is_disabled}
									more_info="The maximum allowed spread between bid and ask price for entering a position."
									field_name="spread_limit"
									value={template["spread_limit"]}
									datatype="float"
									boundaries={FLOAT_BOUNDARIES} // TODO add to input_field_names
									onChange={this.templateUpdate} />
							</Grid>

							<Grid item component={Paper} xs={3} className="template-grid-tile">
								<TemplateInputField
									label="Take Profit"
									more_info="The maximum price gain before exiting the position."
									field_name="take_profit"
									value={template["take_profit"]}
									datatype="float"
									is_disabled={is_disabled}
									boundaries={FLOAT_BOUNDARIES} // TODO add to input_field_names
									onChange={this.templateUpdate} />
							</Grid>

							<Grid item component={Paper} xs={3} className="template-grid-tile">
								<TemplateInputField
									label="Stop Loss"
									more_info="The maximum value the price can move against you before automatically exiting."
									field_name="stop_loss"
									is_disabled={is_disabled}
									value={template["stop_loss"]}
									datatype="float"
									boundaries={FLOAT_BOUNDARIES} // TODO add to input_field_names
									onChange={this.templateUpdate} />
							</Grid>

							{/*<Grid item component={Paper} xs={3} className="template-grid-tile">
								<TemplateInputField
									label="Trailing Stop"
									more_info="Lorem Ipsum"
									field_name="trailing_stop_loss"
									value={template["trailing_stop_loss"]}
									datatype="float"
									boundaries={FLOAT_BOUNDARIES} // TODO add to input_field_names
									onChange={this.templateUpdate} />
							</Grid>*/}
						</Grid>
					</Box>
				</Box>

				{
					!is_disabled &&
					<Container
						component={Paper}
						sx={{"margin" : "40px auto 20px auto", "width" : "90%"}}
					>
						<TemplateInputSignal
							indicator_pool={indicator_pool}
							value={new_signal}
							onChange={(signal, cb) => {
								this.setState({"new_signal":signal}, cb);
							}}
							onDone={this.pushNewSignal}
						/>
					</Container>
				}

				<Box
					sx={{
						"margin":"40px auto 40px auto",
						"width" : "90%",
					}}
				>
					<Typography
						color="font.main"
						variant="h2"
					>Entrance Signals</Typography>
					{/* Below here will handle signal inputs. There needs to be the option for the user to create as many as they'd like for entrances and exits. */}
					{
						entrance_signals && entrance_signals.map((s, idx) => {
							const is_editing = editing.N.length >= idx && editing.N[idx];
							const opt = s.optional ? "(optional)" : "(required)";
							const line = `${s.indicator_title}/${s.title} ${opt}`;
							s["signal_type"] = "N"; /* Adding signal type explicitly. */

							return (is_disabled || !is_editing) ? (
								<Container
									component={Paper}
									key={idx}
									sx={{
										"margin" : "20px auto 20px auto",
										"padding" : "10px"
									}}
								>
									<Box className="flex-row-space-between">
										<Box sx={{ "width" : "100%" }}>
											<Typography
												color="font.main"
												variant="body1"
											>{line}</Typography>
											<Box
												className="flex-row-start"
												sx={{"marginTop" : "5px"}}
											>
												{ this.buildLineFromSignal("N", idx, s) }
											</Box>
										</Box>
										<Box sx={{ "display" : "flex", "marginLeft" : "10px"}}>
											<IconButton
												onClick={() => { this.startEditingSignal("N", idx) }}
												disabled={is_disabled}
											>
												{
													!is_disabled && 
													<Tooltip title="Edit Signal">
														<EditIcon color="primary" />
													</Tooltip>
												}
											</IconButton>

											<IconButton
												onClick={() => { this.deleteSignal("N", idx) }}
												disabled={is_disabled}
											>
												{
													!is_disabled && 
													<Tooltip title="Delete Signal">
														<DeleteIcon color="error" />
													</Tooltip>
												}
											</IconButton>
										</Box>
									</Box>
								</Container>
							) : (
								<Container
									component={Paper}
									key={idx}
									sx={{
										"margin" : "20px auto 20px auto",
										"padding" : "10px"
									}}
								>
									<TemplateInputSignal
										indicator_pool={indicator_pool}
										value={s} // TODO: implement this prop.
										onChange={(new_sig, cb) => {
											this.updateEntranceSignal(idx, new_sig, cb)
										}}
										onDone={() => { this.stopEditingSignal("N", idx)}}/>
								</Container>
							);
						})
					}

				</Box>

				<Box
					sx={{
						"margin":"40px auto 40px auto",
						"width" : "90%",
					}}
				>
					<Typography
						color="font.main"
						variant="h2"
					>Exit Signals</Typography>

						{/* Below here will handle signal inputs. There needs to be the option for the user to create as many as they'd like for entrances and exits. */}
					{
						exit_signals && exit_signals.map((s, idx) => {
							const is_editing = editing.X.length >= idx && editing.X[idx];
							const opt = s.optional ? "(optional)" : "(required)";
							const line = `${s.indicator_title}/${s.title} ${opt}`;
							s["signal_type"] = "X"; /* Adding signal type explicitly. */

							return (is_disabled || !is_editing) ? (
								<Container
									component={Paper}
									key={idx}
									sx={{
										"margin" : "20px auto 20px auto",
										"padding" : "10px"
									}}
								>
									<Box className="flex-row-space-between">
										<Box>
											<Typography
												color="font.main"
												variant="body1"
											>{line}</Typography>
											<Box
												className="flex-row-start"
												sx={{"marginTop" : "5px"}}
											>
												{ this.buildLineFromSignal("X", idx, s) }
											</Box>
										</Box>
										<Box sx={{ "display" : "flex" }}>
											<IconButton
												onClick={() => { this.startEditingSignal("X", idx) }}
												disabled={is_disabled}
											>
												{
													!is_disabled && 
													<Tooltip title="Edit Signal">
														<EditIcon color="primary" />
													</Tooltip>
												}
											</IconButton>
											<IconButton
												onClick={() => { this.deleteSignal("X", idx) }}
												disabled={is_disabled}
											>
												{
													!is_disabled && 
													<Tooltip title="Delete Signal">
														<DeleteIcon color="error" />
													</Tooltip>
												}
											</IconButton>
										</Box>
									</Box>
								</Container>
							) : (
								<Container key={idx} component={Paper}>
									<TemplateInputSignal
										indicator_pool={indicator_pool}
										value={s} // TODO: implement this prop.
										onChange={(new_sig, cb) => {
											this.updateExitSignal(idx, new_sig, cb)
										}}
										onDone={() => {
											this.stopEditingSignal("X", idx)
										}}
									/>
								</Container>
							);
						})
					}
				</Box>
			</Box>
		);
	}

	pushNewSignal() {
		const { onChange } = this.props;
		const { template, new_signal, default_signal } = this.state;
		const { signal_type } = new_signal;
		const { entrance_signals, exit_signals } = template;

		/* Removes signal_type now that it isn't needed. */
		delete new_signal["signal_type"];

		if (signal_type === "N") {
			entrance_signals.push(new_signal);
		} else if (signal_type === "X") {
			exit_signals.push(new_signal);
		} else {
			console.error(`Unexpected signal_type: ${signal_type}`);
			return;
		}

		console.log("Setting to default:");
		console.log({default_signal});
		this.setState({
			template,
			"new_signal" : { ...default_signal }
		}, () => {
			onChange(template);
		});
	}


	// TODO: Error here? signal is not being updated after "Done"

	updateEntranceSignal(idx, new_sig, cb) {
		const { template } = this.state;

		template.entrance_signals[idx] = new_sig;
		this.setState({template}, cb);
	}

	updateExitSignal(idx, new_sig, cb) {
		const { template } = this.state;

		template.exit_signals[idx] = new_sig;
		this.setState({template}, cb);
	}

	startEditingSignal(signal_type, idx) {
		let { editing } = this.state;
		editing[signal_type][idx] = true;
		this.setState({ editing });
	}

	stopEditingSignal(signal_type, idx) {
		let { template, editing } = this.state;
		const { entrance_signals, exit_signals } = template;

		editing[signal_type][idx] = false;
		this.setState({ editing });

		if (signal_type === "N") {
			this.templateUpdate("entrance_signals", entrance_signals);
		} else if (signal_type === "X") {
			this.templateUpdate("exit_signals", exit_signals);
		} else {
			console.error(`Unexpected signal_type: ${signal_type}`);
		}
	}

	templateUpdate(field_name, value) {
		const { onChange } = this.props;
		const { template } = this.state;

		onChange({
			...template,
			[field_name] : value
		});
	}

	setEditingLength() {
		const { template } = this.props;
		let { editing } = this.state;

		console.log({template});

		const n = template.entrance_signals.length;
		const x = template.exit_signals.length;

		for (let i = 0; i < n; i += 1) {
			if (editing["N"].length <= i) editing["N"].push(false);
		}

		for (let i = 0; i < x; i += 1) {
			if (editing["X"].length <= i) editing["X"].push(false);
		}

		this.setState({ editing });
	}

	/**
	 * Based on entrance or exit signal, find the index that has the same line value (an effective hash) and remove it.
	 * @param {*} signal_type 
	 * @param {*} line 
	 */
	deleteSignal(signal_type, idx) {
		const { onChange } = this.props;
		let { template } = this.state;
		// TODO: This contains redundant code, see if you can reduce that.
		switch(signal_type) {
			case "N":
				template.entrance_signals.splice(idx, 1);
				break;
			case "X":
				template.exit_signals.splice(idx, 1);
				break;
			default:
				return;
		}

		this.setState({ template }, () => {
			onChange(template);
		});
	}

	buildLineFromSignal(signal_type, idx, signal) {
		const { direction, granularity, parameters } = signal;
		let arr = [];

		const e_props = {
			"sx" : {
				"display" : "flex",
				"color" : "font.button",
				"backgroundColor" : "secondary.main",
				"borderRadius" : "5px",
				"margin" : "0px 5px 0px 5px",
				"padding" : "0px 3px 0px 3px"
			},
			"variant" : "body2"
		}

		let line = "";

		if (direction.value) {
			switch (direction.value) {
				case "L":
					line += "Direction: Long";
					break;
				case "S":
					line += "Direction: Short";
					break;
				default:
					break;
			}
		} else if (direction.options) {
			if (direction.options.length === 2) {
				line += "Direction: Long and Short"
			}
		}

		arr.push(<Typography color="font.main" {...e_props} key={`${signal_type}-${idx}-direction`}>{line}</Typography>);
		line = "";

		if (granularity.value) line += `Granularity: ${msToDuration(granularity.value)}`;
		else if (granularity.options) line += `Granularity: {${granularity.options.map(o => msToDuration(o)).join(",")}}`;
		else if (granularity.min && granularity.max) line += `Granularity: [${msToDuration(granularity.min)} - ${msToDuration(granularity.max)}]`;

		arr.push(<Typography color="font.main" {...e_props} key={`${signal_type}-${idx}-granularity`}>{line}</Typography>);
		line = "";

		for (let i in Object.keys(parameters)) {
			i = parseInt(i);
			const k = Object.keys(parameters)[i];
			const p = parameters[k];
			if (p.value) {
				line += `P${i + 1}=${p.value}`;
			} else if (p.options) {
				line += `P${i + 1}={${p.options.join(",")}}`;
			} else if (p.min && p.max) {
				line += `P${i + 1}=[${p.min}, ${p.max}]`;
			}

			arr.push(
				<Typography
					color="font.main"
					{...e_props}
					key={`${signal_type}-${idx}-parameters-${i}`}
				>{line}</Typography>
			);
			line = "";
		}

		return arr;
	}

};

export default TemplateEditor;
